<template>
  <div class="pt-4">

    <v-row
      v-if="loggedInUser.dealerKind === 34 "
      justify="center"
    >
      <!-- 대리점 로그인     -->
      <v-col
        cols="12"
        xl="8"
        sm="10"
        md="10"
        style="margin-top:-30px;"

      >

        <v-row>
          <v-col>
            <div class="d-flex pr-1 justify-space-between">
              <div class="d-flex align-center">
                <h5 class="mb-0 mr-2 font-weight-medium">
                  영업 현황
                </h5>
              </div>
            </div>

            <v-row>
              <v-col
                cols="6"
              >
                <router-link :to="{ name: 'dealer', query: {type: 'c'}}">
                  <crypto-currency-card
                    bg-card-color="primary"
                    heading="직접모집대리점"
                    :sub-heading="String(agencyCountInfo.directCount)"
                    main-icon-text-color="primary--text text--lighten-4"
                    main-icon-name="mdi-lighthouse-on"
                  />
                </router-link>
              </v-col>

              <v-col
                cols="6"
              >
                <router-link to="/app/pages/dealer">
                  <crypto-currency-card
                    bg-card-color="red"
                    heading="간접모집대리점"
                    :sub-heading="String(agencyCountInfo.inDirectCount)"
                    main-icon-text-color="warning--text text--lighten-4"
                    main-icon-name="mdi-handshake-outline"
                  />
                </router-link>
              </v-col>
            </v-row>
            <!--
            <v-row>
              <v-col
                cols="12"
                xl="6"
                md="6"
                sm="6"
              >
                <router-link :to="{ name: 'dealer', query: {type: 'c'}}">
                  <analytic-one-card
                    main-icon-name="mdi-hand-pointing-up"
                    main-icon-background-color-class="info lighten-5"
                    main-icon-text-color="info--text"
                    sub-heading-text=""
                    :heading-text="agencyCountInfo.directCount"
                    bottom-button-text="직접모집대리점"
                    bottom-button-icon="mdi-arrow-down-drop-circle-outline"
                    bottom-button-hover-class="hover:bg-info"
                  />
                </router-link>
              </v-col>
              <v-col
                cols="12"
                xl="6"
                md="6"
                sm="6"
              >
                <router-link to="/app/pages/dealer">
                  <analytic-two-card
                    main-icon-name="mdi-account-multiple"
                    main-icon-background-color-class="primary lighten-5"
                    main-icon-text-color="primary--text"
                    sub-heading-text=""
                    :heading-text="agencyCountInfo.inDirectCount"
                    bottom-button-text="간접모집대리점"
                    bottom-button-icon="mdi-arrow-down-drop-circle-outline"
                    bottom-button-hover-class="hover:bg-primary"
                  />
                </router-link>
              </v-col>
            </v-row>
            -->

            <!-- 대리점인경우 -->
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        xl="8"
        sm="10"
        md="10"
        style="margin-top:-30px;"
      >
        <v-row>
          <v-col>
            <div class="d-flex pr-1 justify-space-between">
              <div class="d-flex align-center">
                <h5 class="mb-0 mr-2 font-weight-medium">
                  수익 현황
                  <!--
                  {{ new Date().getFullYear() }}년 {{ new Date().getMonth() + 1 }}월
                  -->
                </h5>
              </div>

            </div>
            <!--
            <base-card>
              <v-list
                dense
                class="transparent"
              >
                <router-link to="/app/pages/salesRevenue?type=a">
                  <v-list-item>
                    <v-list-item-subtitle>
                      <v-icon
                        x-small
                        color="red"
                        class="mx-1"
                      >
                        mdi-circle
                      </v-icon>
                      가맹점 매출 수익
                    </v-list-item-subtitle>
                    <v-list-item-title class="text-right">
                      <template v-if="loading">
                        불러오는 중
                      </template>
                      <template v-else>
                        {{getJoinSalesSum}} 원
                      </template>
                    </v-list-item-title>
                  </v-list-item>
                </router-link>

                <router-link to="/app/pages/salesRevenue?type=e">
                  <v-list-item>
                    <v-list-item-subtitle>
                      <v-icon
                        x-small
                        color="primary"
                        class="mx-1"
                      >
                        mdi-circle
                      </v-icon>
                      모집대리점 매출 수익
                    </v-list-item-subtitle>
                    <v-list-item-title class="text-right">
                      <template v-if="loading">
                        불러오는 중
                      </template>
                      <template v-else>
                        {{getMojibSalesSum}}원
                      </template>
                    </v-list-item-title>
                  </v-list-item>
                </router-link>

                <router-link to="/app/pages/salesRevenue?type=c">
                  <v-list-item>
                    <v-list-item-subtitle>
                      <v-icon
                        x-small
                        color="warning"
                        class="mx-1"
                      >
                        mdi-circle
                      </v-icon>
                      가맹비 수익
                    </v-list-item-subtitle>
                    <v-list-item-title class="text-right">
                      <template v-if="loading">
                        불러오는 중
                      </template>
                      <template v-else>
                        {{getJoinSum}} 원
                      </template>

                    </v-list-item-title>
                  </v-list-item>
                </router-link>
              </v-list>
              <v-divider />
              <v-list
                color="indigo accent-4"
                dark
              >
                <v-list-item>
                  <v-list-item-title>
                    <v-icon
                      x-large
                      color="red"
                      class="mx-2"
                    >
                      mdi-circle-small
                    </v-icon>
                    합계 수익
                  </v-list-item-title>
                  <v-list-item-title class="text-right">
                    <template v-if="loading">
                      불러오는 중
                    </template>
                    <template v-else>
                      {{getBenefitSum}} 원
                    </template>

                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </base-card>
            -->

            <base-card>
              <v-card-text>
                <template v-if="loading">
                  <div class="text-center">
                    <v-progress-circular
                      :size="50"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                  </div>
                </template>
                <template v-else>
                  <div class="revenue_div">
                    <div class="revenue_date">
                      {{ new Date().getMonth() + 1 }}월 수익합계
                    </div>
                    <div class="revenue_txt">
                      {{new Date().toLocaleDateString()}} 기준
<!--                      {{ new Date().getFullYear() }}년 {{ new Date().getMonth() + 1 }}월 {{ new Date().getDay() }}일 기준-->
                    </div>
                  </div>
                  <div class="revenue_line"></div>

                  <template v-if="openBoolean == true">
                    <div class="revenue_detail">
                      <div class="revenue_detail_div" @click="pageMove('/app/pages/salesRevenue?type=a')">

                        <div class="revenue_detail_title">
                          <div class="title1">
                            가맹점 매출 수익
                          </div>
                          <div class="title2">
                            직접 모집한 가맹점으로 부터 발생한 수익
                          </div>
                        </div>
                        <div class="revenue_detail_amount">
                          {{ getJoinSalesSum }}원
                        </div>

                      </div>


                      <div class="revenue_detail_div" @click="pageMove('/app/pages/salesRevenue?type=e')">
                        <div class="revenue_detail_title">
                          <div class="title1">
                            모집대리점 매출 수익
                          </div>
                          <div class="title2">
                            직접 모집한 대리점으로부터 발생한 수익
                          </div>
                        </div>
                        <div class="revenue_detail_amount">
                          {{ getMojibSalesSum }}원
                        </div>

                      </div>


                      <div class="revenue_detail_div" @click="pageMove('/app/pages/salesRevenue?type=c')">
                        <div class="revenue_detail_title">
                          <div class="title1">
                            가맹비 수익
                          </div>
                          <div class="title2">
                            대리점 기능으로부터 발생한 수익(1회성)
                          </div>
                        </div>
                        <div class="revenue_detail_amount">
                          {{ getJoinSum }}원
                        </div>

                      </div>



                      <div class="revenue_line" style="margin-top: 30px;"></div>

                    </div>
                  </template>


                  <div class="revenue_div">
                    <div class="revenue_sum_title">
                      지급금액(예상)
                    </div>
                    <div class="revenue_sum_value">
                      {{ getBenefitSum }}원
                    </div>
                  </div>
                </template>
              </v-card-text>
            </base-card>

            <template v-if="!loading">
              <div id="openBoolean" class="revenue_open_close text-center text--primary" @click="detailOpen">
                <template v-if="openBoolean">
                  <span class="mdi mdi-arrow-expand-up"></span>
                  상세닫기
                </template>
                <template v-else>
                  <span class="mdi mdi-arrow-expand-down"></span>
                  상세열기

                </template>

              </div>
            </template>

          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        xl="8"
        sm="10"
        md="10"
        style="margin-top:-30px;"
      >
        <v-row>
          <v-col>
            <div class="d-flex pr-1 justify-space-between">
              <div class="d-flex align-center">
                <h5 class="mb-0 mr-2 font-weight-medium">
                  공지 사항
                </h5>
              </div>
            </div>
            <base-card>
              <v-card-text>
                <v-simple-table>
                  <thead>
                    <tr>
                      <th class="text-left ">
                        분류
                      </th>
                      <th class="text-center">
                        제목
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-for="(item , index) in this.noticeList"
                    >
                      <tr @click="noticeClick(item.no)">
                        <td><v-chip
                          x-small
                          class="mr-2"
                          color="red"
                          dark
                        >
                          {{item.type}}
                        </v-chip></td>
                        <td>
                          {{ item.title }}
                        </td>
                      </tr>
                      <tr :id="'notice_'+item.no" class="notice_contents" style="display: none;">
                        <td colspan="2" v-html="item.content.replaceAll('\n', '<br>')" style="padding:20px 30px;"></td>
                      </tr>
                    </template>
                  </tbody>

                </v-simple-table>

              </v-card-text>
            </base-card>
            <template>
              <div class="revenue_open_close text-center text--primary" @click="noticeHref">
                <template>
                  더보기
                  <span class="mdi mdi-arrow-expand-right"></span>
                </template>
              </div>
            </template>
          </v-col>
        </v-row>

      </v-col>
    </v-row>


    <v-row
      v-if="loggedInUser.dealerKind === 33"
      justify="center"
    >
      <!--      지사 로그인    -->
      <v-col
        cols="12"
        xl="8"
        sm="10"
        md="10"
        style="margin-top:-30px;"

      >
        <v-row>
          <v-col>
            <div class="d-flex pr-1 justify-space-between">
              <div class="d-flex align-center">
                <h5 class="mb-0 mr-2 font-weight-medium">
                  영업 현황
                </h5>
              </div>
            </div>

            <v-row>
              <v-col
                cols="6"
              >
                <router-link :to="{ name: 'dealer', query: {type: 'b'}}">
                  <crypto-currency-card
                    bg-card-color="primary"
                    heading="소속대리점"
                    :sub-heading="String(agencyCountInfo.directCount)"
                    main-icon-text-color="primary--text text--lighten-4"
                    main-icon-name="mdi-lighthouse-on"
                  />
                </router-link>
              </v-col>

              <v-col
                cols="6"
              >
                <router-link to="/app/pages/dealer?type=a">
                  <crypto-currency-card
                    bg-card-color="red"
                    heading="추천지사"
                    :sub-heading="String(agencyCountInfo.inDirectCount)"
                    main-icon-text-color="warning--text text--lighten-4"
                    main-icon-name="mdi-handshake-outline"
                  />
                </router-link>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        xl="8"
        sm="10"
        md="10"
        style="margin-top:-30px;"

      >
        <v-row>
          <v-col>
            <div class="d-flex pr-1 justify-space-between">
              <div class="d-flex align-center">
                <h5 class="mb-0 mr-2 font-weight-medium">
                  수익 현황
                  <!--
                  {{ new Date().getFullYear() }}년 {{ new Date().getMonth() + 1 }}월
                  -->
                </h5>
              </div>
            </div>
            <base-card>

              <!--
              <v-list
                dense
                class="transparent"
              >
                <router-link to="/app/pages/salesRevenue?type=a">
                  <v-list-item>

                    <v-list-item-subtitle>
                      <v-icon
                        color="red"
                        class="mx-1"
                      >
                        mdi-circle-small
                      </v-icon>
                      가맹점 매출 수익
                    </v-list-item-subtitle>
                    <v-list-item-title class="text-right">
                      <template v-if="loading">
                        불러오는중

                      </template>
                      <template v-else>
                        {{ getJoinSalesSum }} 원
                      </template>
                    </v-list-item-title>

                  </v-list-item>
                </router-link>
                <router-link to="/app/pages/salesRevenue?type=b">
                  <v-list-item>
                    <v-list-item-subtitle>
                      <v-icon
                        color="primary"
                        class="mx-1"
                      >
                        mdi-circle-small
                      </v-icon>
                      소속 대리점 매출 수익
                    </v-list-item-subtitle>
                    <v-list-item-title class="text-right">
                      <template v-if="loading">
                        불러오는중
                      </template>
                      <template v-else>
                        {{ getSosokSalesSum }} 원
                      </template>

                    </v-list-item-title>
                  </v-list-item>
                </router-link>

                <router-link to="/app/pages/salesRevenue?type=c">
                  <v-list-item>
                    <v-list-item-subtitle>
                      <v-icon
                        color="lime"
                        class="mx-1"
                      >
                        mdi-circle-small
                      </v-icon>
                      가맹비 수익
                    </v-list-item-subtitle>
                    <v-list-item-title class="text-right">
                      <template v-if="loading">
                        불러오는중
                      </template>
                      <template v-else>
                        {{ getJoinSum }} 원
                      </template>
                    </v-list-item-title>
                  </v-list-item>
                </router-link>

                <router-link to="/app/pages/salesRevenue?type=d">
                  <v-list-item>
                    <v-list-item-subtitle>
                      <v-icon
                        color="green"
                        class="mx-1"
                      >
                        mdi-circle-small
                      </v-icon>
                      추천지사 수익
                    </v-list-item-subtitle>
                    <v-list-item-title class="text-right">
                      <template v-if="loading">
                        불러오는중
                      </template>
                      <template v-else>
                        {{ getRecommendSum }} 원
                      </template>
                    </v-list-item-title>
                  </v-list-item>
                </router-link>
              </v-list>
              <v-divider />
              <v-list
                color="pink darken-4"
                dark
              >
                <v-list-item>
                  <v-list-item-title>
                    <v-icon
                      x-large
                      color="deep-purple lighten-3"
                      class="mx-2"
                    >
                      mdi-circle-small
                    </v-icon>
                    합계 수익
                  </v-list-item-title>
                  <v-list-item-title class="text-right">
                    <template v-if="loading">
                      불러오는중
                    </template>
                    <template v-else>
                      {{getBenefitSum}} 원
                    </template>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
              -->



              <base-card>
                <v-card-text>
                  <template v-if="loading">
                    <div class="text-center">
                    <v-progress-circular
                      :size="50"
                      color="primary"
                      indeterminate
                    ></v-progress-circular>
                    </div>
                  </template>
                  <template v-else>
                    <div class="revenue_div">
                      <div class="revenue_date">
                        {{ new Date().getMonth() + 1 }}월 수익합계
                      </div>
                      <div class="revenue_txt">
                        {{new Date().toLocaleDateString()}} 기준
<!--                        {{ new Date().getFullYear() }}년 {{ new Date().getMonth() + 1 }}월 {{ new Date().getDay() }}일 기준-->
                      </div>
                    </div>
                    <div class="revenue_line"></div>

                    <template v-if="openBoolean == true">
                      <div class="revenue_detail">
                      <div class="revenue_detail_div" @click="pageMove('/app/pages/salesRevenue?type=a')">

                        <div class="revenue_detail_title">
                          <div class="title1">
                            가맹점 매출 수익
                          </div>
                          <div class="title2">
                            직접 모집한 가맹점으로 부터 발생한 수익
                          </div>
                        </div>
                        <div class="revenue_detail_amount">
                          {{ getJoinSalesSum }}원
                        </div>

                      </div>


                      <div class="revenue_detail_div" @click="pageMove('/app/pages/salesRevenue?type=b')">
                        <div class="revenue_detail_title">
                          <div class="title1">
                            하위 대리점 매출 수익
                          </div>
                          <div class="title2">
                            하위 대리점으로부터 발생한 수익
                          </div>
                        </div>
                        <div class="revenue_detail_amount">
                          {{ getDownSalesSum }}원
                        </div>

                      </div>

                        <div class="revenue_detail_div" @click="pageMove('/app/pages/salesRevenue?type=e')">
                          <div class="revenue_detail_title">
                            <div class="title1">
                              모집 대리점 매출 수익
                            </div>
                            <div class="title2">
                              모집한 대리점으로부터 발생한 수익
                            </div>
                          </div>
                          <div class="revenue_detail_amount">
                            {{ getSosokSalesSum }}원
                          </div>

                        </div>


                      <div class="revenue_detail_div" @click="pageMove('/app/pages/salesRevenue?type=c')">
                        <div class="revenue_detail_title">
                          <div class="title1">
                            가맹비 수익
                          </div>
                          <div class="title2">
                            대리점 기능으로부터 발생한 수익(1회성)
                          </div>
                        </div>
                        <div class="revenue_detail_amount">
                          {{ getJoinSum }}원
                        </div>

                      </div>


                      <div class="revenue_detail_div" @click="pageMove('/app/pages/salesRevenue?type=d')">
                        <div class="revenue_detail_title">
                          <div class="title1">
                            추천지사 수익
                          </div>
                          <div class="title2">
                            추천 지사로부터 발생한 수익
                          </div>
                        </div>
                        <div class="revenue_detail_amount">
                          {{ getRecommendSum }}원
                        </div>

                      </div>


                      <div class="revenue_line" style="margin-top: 30px;"></div>

                    </div>
                    </template>


                    <div class="revenue_div">
                      <div class="revenue_sum_title">
                        지급금액(예상)
                      </div>
                      <div class="revenue_sum_value">
                        {{ getBenefitSum }}원
                      </div>
                    </div>
                  </template>
                </v-card-text>
              </base-card>
            </base-card>

            <template v-if="!loading">
            <div id="openBoolean" class="revenue_open_close text-center text--primary" @click="detailOpen">
              <template v-if="openBoolean">
                <span class="mdi mdi-arrow-expand-up"></span>
                상세닫기
              </template>
              <template v-else>
                <span class="mdi mdi-arrow-expand-down"></span>
                상세열기
              </template>
            </div>
            </template>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        xl="8"
        sm="10"
        md="10"
        style="margin-top:-30px;"
      >
        <v-row>
          <v-col>
            <div class="d-flex pr-1 justify-space-between">
              <div class="d-flex align-center">
                <h5 class="mb-0 mr-2 font-weight-medium">
                  공지 사항
                </h5>
              </div>
              <!-- UI 삭제
              <div>
                <router-link class="font-weight-medium" to="notice">자세히 보기</router-link>
                <v-btn icon>
                  <v-icon>mdi-arrow-right-thin</v-icon>
                </v-btn>
              </div>
              -->
            </div>
            <base-card>
              <v-card-text>
                <v-simple-table>
                  <thead>
                    <tr>
                      <th class="text-left ">
                        분류
                      </th>
                      <th class="text-center">
                        제목
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template
                      v-for="(item , index) in this.noticeList"
                    >
                      <tr @click="noticeClick(item.no)">
                        <td><v-chip
                          x-small
                          class="mr-2"
                          color="red"
                          dark
                        >
                          {{item.type}}
                        </v-chip></td>
                        <td>
                          {{ item.title }}
                        </td>
                      </tr>
                      <tr :id="'notice_'+item.no" class="notice_contents" style="display: none;">
                        <td colspan="2" v-html="item.content.replaceAll('\n', '<br>')" style="padding:20px 30px;"></td>
                      </tr>
                    </template>
                  </tbody>
                </v-simple-table>
              </v-card-text>
            </base-card>
            <template>
              <div class="revenue_open_close text-center text--primary" @click="noticeHref">
                <template>
                  더보기
                  <span class="mdi mdi-arrow-expand-right"></span>
                </template>
              </div>
            </template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import CryptoCurrencyCard from '@/components/card/CryptoCurrencyCard'

  import { dataType } from '@/filter/filter'
import analyticOneCard from "@/components/card/AnalyticCardVersionOne.vue";
import analyticTwoCard from "@/components/card/AnalyticCardVersionTwo.vue";
import SearchAdd from "@/components/base/SearchAdd.vue";

  export default {
    name: 'Home',
    components: {
      'analytic-one-card': analyticOneCard,
      'analytic-two-card': analyticTwoCard,
      CryptoCurrencyCard,
    },
    data() {
      return {
        openBoolean: false

      }
    },

    created () {
      this.setSelectedMenu('Home')
      const today = dataType()
      let date = new Date();
      let preDay = dataType(new Date(date.getFullYear(), date.getMonth(), 1))
      this.fetchAgencyCount()

      this.fetchNoticeList({
        // 공지사항 전체를 가져오기 위한 날짜 설정
        startDt: '2000-01-01',
        endDt: today,
        startNo: 1,
        endNo: 3,
        title: null,
        content: null,
        type: null,
        dealerKind: this.loggedInUser.dealerKind,
        userId: this.loggedInUser.dealerId,
      })
      this.fetchMerchantIncomeSum({startDt: preDay,
        endDt: today,
        startNo: 1,
        endNo: 9999,
        title: null,
        content: null,
        type: null,
        userId: this.loggedInUser.dealerId,
        dealerKind: this.loggedInUser.dealerKind,})

    },
    computed: {
      ...mapGetters(
        ['agencyCountInfo',
          'loggedInUser',
          'noticeList',
          'getJoinSalesSum',
          'getMojibSalesSum',
          'getSosokSalesSum',
          'getDownSalesSum',
          'getJoinSum',
          'getRecommendSum',
          'getBenefitSum',
          'loading',
          'getThemeMode',
        ]),
    },
    mounted() {
      if(this.getThemeMode.verticalCompactSidebarDrawer) {
        const width = window.innerWidth;

        if (width < 1120) {
          setTimeout(() => {
            this.toggleCompactVerticalSidebarDrawer();
          }, 2000);
        }
      }

    },
    methods: {
      ...mapActions(['fetchAgencyCount', 'fetchNoticeList','fetchMerchantIncomeSum']),
      ...mapMutations(['setSelectedMenu','toggleCompactVerticalSidebarDrawer']),
      noticeClick(no) {
        let id = '#notice_'+no;

        const notice = document.querySelector(id);
        if(notice.style.display === 'none') {

          const noticeList = document.getElementsByClassName('notice_contents');
          Array.from(noticeList).forEach((item) => {
            item.style.display = 'none';
          })


          notice.style.display = 'contents';


        } else {
          notice.style.display = 'none';
        }


      },

      detailOpen() {
        if(this.openBoolean) {
          this.openBoolean = false
        } else {
          this.openBoolean = true
        }
      },

      pageMove(path) {
        this.$router.push(path);
      },

      noticeHref(){
        this.$router.push('/app/pages/notice');
      }
    },

  }
</script>
<style>
  .revenue_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:15px;
  }

  .revenue_div .revenue_date {
    width:50%;
    font-size:18px;
    font-weight: bold;
  }

  .revenue_div .revenue_txt {
    font-size:12px;
    text-align: right;
  }


  .revenue_line {
    height:1px;
    background-color: #4a5568;
  }

  .revenue_div .revenue_sum_title {
    width:60%;
    font-size:1.2em;
  }

  .revenue_div .revenue_sum_value {
    font-size:1.2em;
    text-align: right;
    font-weight: bold;
  }


  .revenue_detail_div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0px 10px;
  }

  .revenue_detail_div:first-child {
    margin-top:30px;
  }

  .revenue_detail_div {
    margin-top:20px;
  }



  .revenue_detail_title {

  }

  .revenue_detail_amount {
    font-size:16px;
  }

  .revenue_detail_title .title1 {
    font-size:1.1em;
  }
  .revenue_detail_title .title2 {
    font-size:0.7em;
    margin-top:5px;
  }


  .revenue_open_close {
    margin-top:20px;
    cursor: pointer;
  }

</style>
